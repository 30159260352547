<template>
  <b-card>
    <b-card-body
      class="mt-0 mb-0"
      style="padding: 1.1rem;"
    >
      <b-card-title
        class="mb-0 mt-0 pt-0"
      >
        <div class="d-flex">
          <h1 class="mb-1 ml-50">
            Basic Information
          </h1>
        </div>
      </b-card-title>
    </b-card-body>
    <b-row>

      <!-- Left Col: Table -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column mb-1"
      >
        <!-- Patient Avatar & Action Buttons -->
        <div class="d-flex justify-content-start ml-1">
          <b-avatar
            :src="patientData.avatar"
            :text="avatarText(patientData.FullName)"
            :variant="`light-${resolvePatientVariant()}`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ patientData.FullName }}
              </h4>
              <span class="card-text">{{ patientData.UserName }}</span>
            </div>
            <div class="d-flex">
              <b-button
                :to="{ name: 'patient-edit', params: { id: patientData.Id } }"
                variant="primary"
              >
                Edit
              </b-button>
              <b-button
                variant="outline-danger"
                class="ml-1"
                @click="deletePatient(patientData)"
              >
                Delete
              </b-button>
            </div>
          </div>
        </div>

      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="5"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Name</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ patientData.FullName }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BriefcaseIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Mobile</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ patientData.Mobile }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td class="pb-50" dir="auto">
              {{ patientData.Address }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="KeyIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">HearAboutClinicFrom</span>
            </th>
            <td class="pb-50 text-capitalize">
                {{ patientData.HearAboutClinicFrom.Name }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import patientStoreModule from '@/store/main/PatientStoreModule'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BCardBody, BCardTitle,
  },
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const PATIENT_STORE_MODULE_NAME = 'patient-store'
    // Register module
    if (!store.hasModule(PATIENT_STORE_MODULE_NAME)) store.registerModule(PATIENT_STORE_MODULE_NAME, patientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_STORE_MODULE_NAME)) store.unregisterModule(PATIENT_STORE_MODULE_NAME)
    })

    function deletePatient(patient) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mr-1',
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this patient ${patient.FullName}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('patient-store/deletePatient', { id: patient.Id })
            .then(() => {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
              this.$router.push({ name: 'patient-list' })
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Error happenied when deleting patient',
                'error',
              )
            })
        } else if (result.dismiss === 'cancel') {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error',
          )
        }
      })
    }

    const resolvePatientVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }
    return {
      avatarText,
      resolvePatientVariant,
      deletePatient,
    }
  },
}
</script>

<style>

</style>
