<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="primary"
      :show="patientData === undefined"
    >
      <div class="alert-body">
        No patient selected to view. Check
        <b-link
          class="alert-link"
          :to="{ name: 'patient-list'}"
        >
          Patient List
        </b-link>
        for more patients.
      </div>
    </b-alert>

    <b-tabs
      v-if="patientData"
    >
      <!-- Tab: 1 -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">All Information</span>
        </template>
        <patient-view-basic-info :patient-data="patientData" />
        <patient-view-details-info :patient-data="patientData" />
      </b-tab>

      <!-- Tab: 2 -->
      <b-tab>
        <template #title>
          <feather-icon
            icon="ServerIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Visits</span>
        </template>
        <patient-view-visits-info :patient-data="patientData" />
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
import {
  BTab, BTabs, BAlert, BLink,
} from 'bootstrap-vue'
import PatientStoreModule from '@/store/main/PatientStoreModule'
import PatientViewBasicInfo from './PatientViewBasicInfo.vue'
import PatientViewDetailsInfo from './PatientViewDetailsInfo.vue'
import PatientViewVisitsInfo from './PatientViewVisitsInfo.vue'

export default {
  components: {
    BTab,
    BTabs,
    BAlert,
    BLink,

    // Local Components
    PatientViewBasicInfo,
    PatientViewDetailsInfo,
    PatientViewVisitsInfo,
  },
  setup() {
    const PATIENT_STORE_MODULE_NAME = 'patient-store'
    // Register module
    if (!store.hasModule(PATIENT_STORE_MODULE_NAME)) store.registerModule(PATIENT_STORE_MODULE_NAME, PatientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_STORE_MODULE_NAME)) store.unregisterModule(PATIENT_STORE_MODULE_NAME)
    })

    //----------------------------------------------------------------------------------------------------------------------------
    const patientData = ref(null)

    if (router.currentRoute.params.id === undefined || router.currentRoute.params.id === null) {
      patientData.value = undefined
      return {
        patientData,
      }
    }

    store.dispatch('patient-store/getPatientById', { id: router.currentRoute.params.id })
      .then(response => { patientData.value = response.data.Data })
      .catch(error => {
        if (error.response.status === 404) {
          patientData.value = undefined
        }
      })
    //----------------------------------------------------------------------------------------------------------------------------

    return {
      patientData,
    }
  },
}
</script>

<style>

</style>
