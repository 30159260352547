<template>
  <b-card>
    <b-card-body
      class="mt-0 mb-0"
      style="padding: 1.1rem;"
    >
      <b-card-title
        class="mb-0 mt-0 pt-0"
      >
        <div class="d-flex">
          <h1 class="mb-1 ml-50">
            Details Information
          </h1>
        </div>
      </b-card-title>
    </b-card-body>
    <b-row>
      <b-col
        cols="21"
        xl="5"
        class="d-flex justify-content-between flex-column mb-1"
      >
        <table class="mt-2 mt-xl-0 w-100 ml-2">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="MailIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Email</span>
            </th>
            <td class="pb-50">
              {{ patientData.Email }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="PhoneIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Mobile</span>
            </th>
            <td class="pb-50">
              {{ patientData.Mobile }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="FileIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">NationalId</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ patientData.NationalId }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="TrendingUpIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Gender</span>
            </th>
            <td>
                {{ patientData.Gender.Name }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="TrendingUpIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Social Status</span>
            </th>
            <td>
                {{ patientData.SocialStatus.Name }}
            </td>
          </tr>
          <br>
          <tr>
            <th>
              <feather-icon
                icon="MapPinIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Address</span>
            </th>
            <td dir="auto">
              {{ patientData.Address }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="HomeIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Country</span>
            </th>
            <td>
              {{ patientData.Country }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="ArrowUpRightIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">City</span>
            </th>
            <td>
              {{ patientData.City }}
            </td>
          </tr>
          <tr>
            <th>
              <feather-icon
                icon="AlertCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Area</span>
            </th>
            <td>
              {{ patientData.Area }}
            </td>
          <tr>
            <th>
              <feather-icon
                icon="AlertCircleIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">Chronic Diseases</span>
            </th>
            <td>
              {{ patientChronicDiseases }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardBody, BCardTitle,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import { onUnmounted, computed } from '@vue/composition-api'
import patientStoreModule from '@/store/main/PatientStoreModule'

export default {
  components: {
    BCard, BRow, BCol, BCardBody, BCardTitle,
  },
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const PATIENT_STORE_MODULE_NAME = 'patient-store'
    // Register module
    if (!store.hasModule(PATIENT_STORE_MODULE_NAME)) store.registerModule(PATIENT_STORE_MODULE_NAME, patientStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PATIENT_STORE_MODULE_NAME)) store.unregisterModule(PATIENT_STORE_MODULE_NAME)
    })

    function deletePatient(patient) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mr-1',
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this patient ${patient.FullName}!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('patient-store/deletePatient', { id: patient.Id })
            .then(() => {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
              this.$router.push({ name: 'patient-list' })
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Error happenied when deleting patient',
                'error',
              )
            })
        } else if (result.dismiss === 'cancel') {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error',
          )
        }
      })
    }

    const patientChronicDiseases = computed(() => props.patientData.ChronicDiseases.map(v => v.Name))

    const resolvePatientVariant = () => {
      switch (Math.floor(Math.random() * (4 - 0)) + 0) {
        case 0: return 'primary'
        case 1: return 'warning'
        case 2: return 'success'
        case 3: return 'info'
        case 4: return 'danger'
        default: return 'primary'
      }
    }
    return {
      avatarText,
      patientChronicDiseases,
      resolvePatientVariant,
      deletePatient,
    }
  },
}
</script>

<style>

</style>
