<template>
  <b-sidebar
    id="appointment-operation-sidebar"
    :visible="isAppointmentOperationSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-appointment-operation-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0"
          v-if= "appointmentToBeUpdated == null">
           Add New Appointment
        </h5>
        <h5 class="mb-0"
          v-if= "appointmentToBeUpdated != null">
           Edit Appointment
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >

        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Add Doctor -->
          <validation-provider
            #default="validationContext"
            name="Doctor"
          >
            <b-form-group
              label=""
              label-for="add-doctor"
              :state="getValidationState(validationContext)"
            >
              <template>
                <feather-icon
                  icon="UserIcon"
                  class="rounded-circle d-inline-block mr-50"
                  style="color:green"
                />
                <label> Add Doctor </label>
              </template>
              <v-select
                v-model="appointmentData.doctor"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="doctorOptions"
                :reduce="val => val.id"
                label="name"
                input-id="doctor"
              >
                <template #option="{ id, name }">
                  <feather-icon
                    icon="UserIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="height:10px;width:10px"
                  />
                  <span> {{ name }}</span>
                </template>

                <template #selected-option="{ id, name }">
                  <feather-icon
                    icon="UserIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="height:10px;width:10px"
                  />
                  <span> {{ name }}</span>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Add Patient -->
          <validation-provider
              #default="validationContext"
              name="Patient"
              rules="required"
            >
              <b-form-group
                label=""
                label-for="add-patient"
                :state="getValidationState(validationContext)"
              >
                <template>
                  <feather-icon
                    icon="UserIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="color:red"
                  />
                  <label> Add Patient </label>
                </template>
                <v-select
                  v-model="appointmentData.patient"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="patientOptions"
                  :reduce="val => val.id"
                  label="name"
                  input-id="patient"
                >
                  <template #option="{ id, name }">
                    <feather-icon
                      icon="UserIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>

                  <template #selected-option="{ id, name }">
                    <feather-icon
                      icon="UserIcon"
                      class="rounded-circle d-inline-block mr-50"
                      style="height:10px;width:10px"
                    />
                    <span> {{ name }}</span>
                  </template>
                </v-select>

                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

          <!-- day -->
          <validation-provider
            #default="validationContext"
            name="Appointment Date"
            rules="required"
          >
            <b-form-group
              label="Appointment Date"
              label-for="appointment-date"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="appointmentData.day"
                class="form-control"
                :config="{ enableTime: false, dateFormat: 'd-m-Y' /*, minDate: 'today', maxDate: new Date().fp_incr(365) */ }"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Start Time -->
          <validation-provider
            #default="validationContext"
            name="Start Time"
            rules="required"
          >
            <b-form-group
              label="Start Time"
              label-for="start-time"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="appointmentData.start"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K', minTime: '9:00', maxTime: '21:00' }"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- End Time -->
          <validation-provider
            #default="validationContext"
            name="End Time"
            rules="required"
          >
            <b-form-group
              label="End Time"
              label-for="end-time"
              :state="getValidationState(validationContext)"
            >
              <flat-pickr
                v-model="appointmentData.end"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'h:i K', minTime: '9:00', maxTime: '21:00' }"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- ReminderMeAfter6Months -->
          <b-form-checkbox
            id="event-note"
            v-model="appointmentData.reminderMeAfter6Months"
            class="mb-1"
          >
           <label style="font-size: 0.857rem;">Reminder Me After 6 Months</label>
          </b-form-checkbox>

          <!-- Clinic -->
          <validation-provider
            #default="validationContext"
            name="Clinic"
          >
            <b-form-group
              label="Clinic"
              label-for="clinic"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="appointmentData.clinic"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="clinicOptions"
                label="value"
                :reduce="clinic => clinic.id"
                input-id="clinic"
              >
                <template #option="{ id, value }">
                  <feather-icon
                    icon="MapPinIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="height:10px;width:10px"
                  />
                  <span> {{ value }}</span>
                </template>

                <template #selected-option="{ id, value }">
                  <feather-icon
                    icon="MapPinIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="height:10px;width:10px"
                  />
                  <span> {{ value }}</span>
                </template>
              </v-select>

              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Reason -->
          <validation-provider
            #default="validationContext"
            name="Reason"
          >
            <b-form-group
              label="Reason"
              label-for="reason"
            >
              <v-select
                v-model="appointmentData.reason"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="appointmentReasonOptions"
                label="value"
                :reduce="reason => reason.id"
                input-id="reason"
              >
                <template #option="{ id, value }">
                  <feather-icon
                    icon="ClipboardIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="height:10px;width:10px"
                  />
                  <span> {{ value }}</span>
                </template>

                <template #selected-option="{ id, value }">
                  <feather-icon
                    icon="ClipboardIcon"
                    class="rounded-circle d-inline-block mr-50"
                    style="height:10px;width:10px"
                  />
                  <span> {{ value }}</span>
                </template>
              </v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Note  -->
          <b-form-group
            label="Note "
            label-for="event-note"
          >
            <b-form-textarea
              id="event-note"
              v-model="appointmentData.note"
            />
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              {{ appointmentData.id ? 'Update' : 'Add ' }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
            >
              Reset
            </b-button>
          </div>
          <br />
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormTextarea, BButton, BFormInvalidFeedback, BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  ref, onUnmounted, computed, watch, toRefs,
} from '@vue/composition-api'
import { required, alphaNum } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import store from '@/store'
import flatPickr from 'vue-flatpickr-component'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import appointmentStoreModule from '@/store/main/AppointmentStoreModule'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,
    BFormTextarea,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAppointmentOperationSidebarActive',
    event: 'update:is-appointment-operation-sidebar-active',
  },
  props: {
    isAppointmentOperationSidebarActive: {
      type: Boolean,
      required: true,
    },
    appointmentToBeUpdated: {
      type: Object,
      required: false,
    },
    patientPassed: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      required,
      alphaNum,
    }
  },
  setup(props, { emit }) {
    const APPOINTMENT_APP_STORE_MODULE_NAME = 'appointment-store'
    // Register module
    if (!store.hasModule(APPOINTMENT_APP_STORE_MODULE_NAME)) store.registerModule(APPOINTMENT_APP_STORE_MODULE_NAME, appointmentStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APPOINTMENT_APP_STORE_MODULE_NAME)) store.unregisterModule(APPOINTMENT_APP_STORE_MODULE_NAME)
    })

    // Use toast
    const toast = useToast()
    /*
    const today = computed(() => {
      const date = new Date()
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()
      let monthUpdated = ''
      let dayUpdated = ''

      if (month < 10) {
        monthUpdated = `0${month}`
      } else {
        monthUpdated = `${month}`
      }

      if (day < 10) {
        dayUpdated = `0${day}`
      } else {
        dayUpdated = `${day}`
      }

      return `${dayUpdated}-${monthUpdated}-${year}`
    })
    */

    const blankAppointmentData = {
      id: 0,
      doctor: '',
      patient: '',
      day: '',
      start: '9:00 AM',
      end: '10:00 AM',
      clinic: 0,
      reason: 0,
      note: '',
      reminderMeAfter6Months: false,
    }
    const appointmentData = ref(JSON.parse(JSON.stringify(blankAppointmentData)))
    const resetappointmentData = () => {
      appointmentData.value = JSON.parse(JSON.stringify(blankAppointmentData))
    }

    watch(toRefs(props).appointmentToBeUpdated, appointment => {
      if (appointment != null) {
        blankAppointmentData.id = appointment.id
        blankAppointmentData.doctor = appointment.extendedProps.doctor
        blankAppointmentData.patient = appointment.extendedProps.patient
        blankAppointmentData.day = appointment.extendedProps.day
        blankAppointmentData.start = appointment.start.substr(11, 8)
        blankAppointmentData.end = appointment.end.substr(11, 8)
        blankAppointmentData.clinic = appointment.extendedProps.clinic
        blankAppointmentData.reason = appointment.extendedProps.reason
        blankAppointmentData.note = appointment.extendedProps.note
        blankAppointmentData.reminderMeAfter6Months = appointment.extendedProps.reminderMeAfter6Months
        resetappointmentData()
      } else {
        blankAppointmentData.id = 0
        blankAppointmentData.doctor = ''
        blankAppointmentData.patient = ''
        blankAppointmentData.day = ''
        blankAppointmentData.start = '9:00 AM'
        blankAppointmentData.end = '10:00 AM'
        blankAppointmentData.clinic = 0
        blankAppointmentData.reason = 0
        blankAppointmentData.note = ''
        blankAppointmentData.reminderMeAfter6Months = false
        resetappointmentData()
      }
    })
    watch(toRefs(props).patientPassed, patient => {
      if (props.appointmentToBeUpdated == null) {
        blankAppointmentData.id = 0
        blankAppointmentData.doctor = ''
        blankAppointmentData.patient = ''
        blankAppointmentData.day = ''
        blankAppointmentData.start = '9:00 AM'
        blankAppointmentData.end = '10:00 AM'
        blankAppointmentData.clinic = 0
        blankAppointmentData.reason = 0
        blankAppointmentData.note = ''
        blankAppointmentData.reminderMeAfter6Months = false
        resetappointmentData()
      }
      if (patient != null) {
        blankAppointmentData.patient = patient.Id
        resetappointmentData()
      }
    })

    const onSubmit = () => {
      if (appointmentData.value.id <= 0) {
        store.dispatch('appointment-store/addAppointment', {
          DoctorId: appointmentData.value.doctor,
          PatientId: appointmentData.value.patient,
          Day: appointmentData.value.day,
          StartTime: appointmentData.value.start,
          EndTime: appointmentData.value.end,
          ReasonId: appointmentData.value.reason,
          Note: appointmentData.value.note,
          BranchId: appointmentData.value.clinic,
          ReminderMeAfter6Months: appointmentData.value.reminderMeAfter6Months,
        }).then(response => {
          if (response.status === 200 && response.data.IsSucceeded === true) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Operation Done',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'You have successfully add new appointment!',
              },
            })
            emit('refetch-data')
            emit('update:is-appointment-operation-sidebar-active', false)
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Happened',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      } else {
        store.dispatch('appointment-store/editAppointment', {
          Id: appointmentData.value.id,
          DoctorId: appointmentData.value.doctor,
          PatientId: appointmentData.value.patient,
          Day: appointmentData.value.day,
          StartTime: appointmentData.value.start,
          EndTime: appointmentData.value.end,
          ReasonId: appointmentData.value.reason,
          Note: appointmentData.value.note,
          BranchId: appointmentData.value.clinic,
          ReminderMeAfter6Months: appointmentData.value.reminderMeAfter6Months,
        }).then(response => {
          if (response.status === 200 && response.data.IsSucceeded === true) {
            toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Operation Done',
                icon: 'CheckIcon',
                variant: 'success',
                text: 'You have successfully save appointment!',
              },
            })
            emit('refetch-data')
            emit('update:is-appointment-operation-sidebar-active', false)
          }
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error Happened',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
      }
    }

    // *===============================================---*
    // *--------- Dropdowns ------------------------------*
    // *===============================================---*
    store.dispatch('appointment-store/getDoctorOptions')
    const doctorOptions = computed(() => store.state['appointment-store'].doctorOptions)

    store.dispatch('appointment-store/getClinicOptions')
    const clinicOptions = computed(() => store.state['appointment-store'].clinicOptions)

    store.dispatch('appointment-store/getAppointmentReasonOptions')
    const appointmentReasonOptions = computed(() => store.state['appointment-store'].appointmentReasonOptions)

    store.dispatch('appointment-store/getPatientOptions')
    const patientOptions = computed(() => store.state['appointment-store'].patientOptions)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetappointmentData)

    return {
      appointmentData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      doctorOptions,
      clinicOptions,
      appointmentReasonOptions,
      patientOptions,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add -new-doctor-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
