<template>

  <div>

    <appointment-operation
     :is-appointment-operation-sidebar-active.sync="isAppointmentOperationSidebarActive"
     :appointment-to-be-updated="appointmentToBeUpdated"
     :patient-passed="patientPassed"
     @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <!-- Table Top -->
      <div class="m-1">
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mt-0.5 mb-1 mb-md-0"
          >
            <h2
              class="text-nowrap font-weight-bolder"
            >
             {{patientData.FullName}}
            </h2>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Type:"
              label-cols-md="2"
              label-align-sm="right"
              class="mb-0"
            >
              <v-select
                v-model="isReminderAfter6Months"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeOptions"
                :clearable="false"
                :reduce="val => val.id"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                @click="addVisit()"
              >
                <span class="text-nowrap">Add Appointment</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <!-- Table Content -->
      <b-table
        ref="refVisitListTable"
        v-if="patientData"
        class="position-relative"
        :items="visitsList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Visit -->
        <template #cell(DoctorName)="data">
          <router-link
            v-if="data.item.Doctor"
            :to="{ name: 'doctor-view', params: { id: data.item.Doctor.Id } }"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            {{ data.item.Doctor.Name }}
          </router-link>
        </template>
        <template #cell(Day)="data">
          <router-link
            v-if="data.item.Appointment"
            :to="{ name: 'visit-operation', query: { appointment_id: data.item.Appointment.Id }}"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            {{ data.item.Appointment.Day }}
          </router-link>
        </template>
        <template #cell(From)="data">
          <router-link
            v-if="data.item.Appointment"
            :to="{ name: 'visit-operation', query: { appointment_id: data.item.Appointment.Id }}"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            {{ data.item.Appointment.From }}
          </router-link>
        </template>
        <template #cell(To)="data">
          <router-link
            v-if="data.item.Appointment"
            :to="{ name: 'visit-operation', query: { appointment_id: data.item.Appointment.Id }}"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            {{ data.item.Appointment.To }}
          </router-link>
        </template>
        <template #cell(Invoice)="data">
          <router-link
            v-if="data.item.Invoice"
            :to="{ name: 'invoice-operation', query: { appointment_id: data.item.Appointment.Id } }"
            class="font-weight-bold d-block text-nowrap"
            target="_blank"
          >
            View Invoice
          </router-link>
        </template>
        <template #cell(Type)="data">
        <b-badge
          v-if="data.item.Appointment.IsReminderAfter6Months"
          pill
          variant="info"
        >
          Reminder
        </b-badge>
        <b-badge
          v-if="!data.item.Appointment.IsReminderAfter6Months"
          pill
          variant="primary"
        >
          Main
        </b-badge>
      </template>
        <template #cell(Status)="data">
        <b-badge
          v-if="data.item.Appointment.IsUpComing"
          pill
          variant="success"
        >
          UpComing
        </b-badge>
        <b-badge
          v-if="!data.item.Appointment.IsUpComing"
          pill
          variant="secondary"
        >
          Past
        </b-badge>
      </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`visit-row-${data.item.Id}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mx-1"
              @click="editVisit(data.item)"
            />
            <feather-icon
              :id="`visit-row-${data.item.Id}-delete-icon`"
              icon="TrashIcon"
              size="16"
              class="cursor-pointer"
              @click="deleteVisit(data.item)"
            />
          </div>
        </template>

      </b-table>

      <!-- Table bottom: Pagination-->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing
              </span>
              <v-select
                v-model="perPage"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="perPageOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
              />
              <span class="text-nowrap"> {{ totalVisits }} records </span>
            </div>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end mt-1"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} records</span>
          </b-col>

          <b-col
            cols="12"
            sm="4"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalVisits"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BButton, BBadge, BFormGroup,
} from 'bootstrap-vue'
import {
  ref, onUnmounted, watch, computed,
} from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import AppointmentOperation from '@/views/main/appointment/appointment-operation/AppointmentOperation.vue'
import VisitStoreModule from '@/store/main/VisitStoreModule'

export default {
  components: {
    BCard,
    BRow,
    BButton,
    BCol,
    BTable,
    BPagination,
    vSelect,
    AppointmentOperation,
    BBadge,
    BFormGroup,
  },
  directives: {
    Ripple,
  },
  props: {
    patientData: {
      type: Object,
      required: true,
    },
  },
  model: {
    prop: 'isAppointmentOperationSidebarActive',
    event: 'update:is-appointment-operation-sidebar-active',
  },
  data() {
    return {
      typeOptions:
      [
        {
          name: 'All',
          id: null,
        },
        {
          name: 'Main',
          id: false,
        },
        {
          name: 'Reminder',
          id: true,
        },
      ],
    }
  },
  setup(props) {
    const VISIT_STORE_MODULE_NAME = 'visit-store'
    // Register module
    if (!store.hasModule(VISIT_STORE_MODULE_NAME)) store.registerModule(VISIT_STORE_MODULE_NAME, VisitStoreModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(VISIT_STORE_MODULE_NAME)) store.unregisterModule(VISIT_STORE_MODULE_NAME)
    })

    const refVisitListTable = ref(null)
    const isAppointmentOperationSidebarActive = ref(false)
    const toast = useToast()

    // Table Handlers
    const tableColumns = [
      { key: 'DoctorName', sortable: false },
      { key: 'Day', sortable: false },
      { key: 'From', sortable: false },
      { key: 'To', sortable: false },
      { key: 'Invoice', sortable: false },
      { key: 'Type', sortable: false },
      { key: 'Status', sortable: false },
      { key: 'actions' },
    ]
    const perPage = ref(10)
    const totalVisits = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const sortBy = ref('CreationDate')
    const isSortDirDesc = ref(false)
    const doctorFilter = ref(null)
    const patientFilter = ref(null)
    const isReminderAfter6Months = ref(null)
    const appointmentToBeUpdated = ref(null)
    const patientPassed = ref(null)

    const dataMeta = computed(() => {
      const localItemsCount = refVisitListTable.value ? refVisitListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalVisits.value,
      }
    })
    const refetchData = () => {
      refVisitListTable.value.refresh()
    }
    watch([currentPage, perPage, doctorFilter, patientFilter, isReminderAfter6Months, appointmentToBeUpdated, patientPassed], () => {
      refetchData()
    })

    // *===============================================---*
    // *--------- Requests ------------------------------*
    // *===============================================---*
    const visitsList = (ctx, callback) => {
      store
        .dispatch('visit-store/getAllVisits', {
          pageSize: perPage.value,
          pageNumber: currentPage.value,
          // sortBy: sortBy.value,
          // sortDesc: isSortDirDesc.value,
          doctorId: '',
          patientId: props.patientData?.Id,
          isReminderAfter6Months: isReminderAfter6Months.value,
        })
        .then(response => {
          if (response.data.IsSucceeded === true) {
            totalVisits.value = response.data.Data.TotalCount
            const visits = response.data.Data.List
            callback(visits)
          }
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching visits list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }
    function deleteVisit(visit) {
      const swalWithBootstrapButtons = this.$swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger mr-1',
        },
        buttonsStyling: false,
      })
      swalWithBootstrapButtons.fire({
        title: 'Are you sure?',
        text: `You won't be able to revert this visit ${visit.Appointment.Day} !`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then(result => {
        if (result.isConfirmed) {
          store
            .dispatch('visit-store/removeAppointment', { id: visit.Appointment.Id })
            .then(() => {
              swalWithBootstrapButtons.fire(
                'Deleted!',
                'Your file has been deleted.',
                'success',
              )
              refetchData()
            })
            .catch(() => {
              swalWithBootstrapButtons.fire(
                'Cancelled',
                'Error happenied when deleting visit',
                'error',
              )
            })
        } else if (result.dismiss === 'cancel') {
          swalWithBootstrapButtons.fire(
            'Cancelled',
            'Your imaginary file is safe :)',
            'error',
          )
        }
      })
    }
    function editVisit(visit) {
      isAppointmentOperationSidebarActive.value = true
      patientPassed.value = null
      store.dispatch('appointment-store/getAppointment', { id: visit.Appointment.Id })
        .then(response => {
          if (response.status === 200 && response.data.IsSucceeded === true) {
            appointmentToBeUpdated.value = response.data.Data
          }
        })
    }
    function addVisit() {
      isAppointmentOperationSidebarActive.value = true
      patientPassed.value = props.patientData
      appointmentToBeUpdated.value = null
    }

    return {
      // Sidebar
      tableColumns,
      perPage,
      currentPage,
      totalVisits,
      dataMeta,
      perPageOptions,
      doctorFilter,
      patientFilter,
      isReminderAfter6Months,
      sortBy,
      isSortDirDesc,
      refVisitListTable,
      refetchData,
      isAppointmentOperationSidebarActive,
      visitsList,
      deleteVisit,
      editVisit,
      addVisit,
      appointmentToBeUpdated,
      patientPassed,

      // Filter
      avatarText,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
