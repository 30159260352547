import axios from '@axios'

export default {
  namespaced: true,
  state: {
    doctorOptions: [],
    clinicOptions: [],
    appointmentReasonOptions: [],
    patientOptions: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
    SET_DoctorOptions(state, doctorOptions) {
      state.doctorOptions = doctorOptions
    },
    SET_PatientOptions(state, patientOptions) {
      state.patientOptions = patientOptions
    },
    SET_ClinicOptions(state, clinicOptions) {
      state.clinicOptions = clinicOptions
    },
    SET_AppointmentReasonOptions(state, appointmentReasonOptions) {
      state.appointmentReasonOptions = appointmentReasonOptions
    },
  },
  actions: {
    addAppointment(ctx, event) {
      // console.log(event)
      return new Promise((resolve, reject) => {
        axios
          .post('/api/appointment/add', event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateAppointment(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/appointment/edit', event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeAppointment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/appointment/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAppointment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/appointment/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDoctorOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/doctors')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const doctors = []
              response.data.Data.List.forEach(element => {
                doctors.push({
                  id: element.Id,
                  name: element.FullName,
                })
              })
              commit('SET_DoctorOptions', doctors)
              resolve(doctors)
            }
          })
          .catch(error => reject(error))
      })
    },
    getClinicOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/branches')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const clinics = []
              response.data.Data.List.forEach(element => {
                clinics.push({
                  id: element.Id,
                  value: element.Name,
                })
              })
              commit('SET_ClinicOptions', clinics)
              resolve(clinics)
            }
          })
          .catch(error => reject(error))
      })
    },
    getAppointmentReasonOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/appointment_reasons')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const appointmentReasons = []
              response.data.Data.List.forEach(element => {
                appointmentReasons.push({
                  id: element.Id,
                  value: element.Name,
                })
              })
              commit('SET_AppointmentReasonOptions', appointmentReasons)
              resolve(appointmentReasons)
            }
          })
          .catch(error => reject(error))
      })
    },
    getPatientOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/patients')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const patients = []
              response.data.Data.List.forEach(element => {
                patients.push({
                  id: element.Id,
                  name: element.FullName,
                })
              })
              commit('SET_PatientOptions', patients)
              resolve(patients)
            }
          })
          .catch(error => reject(error))
      })
    },
    editAppointment(ctx, event) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/appointment/edit', event)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
