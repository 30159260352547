import axios from '@axios'

export default {
  namespaced: true,
  state: {
    hearAboutClinicFrom: [],
    chronicDiseases: [],
  },
  getters: {},
  mutations: {
    SET_HearAboutClinicFrom(state, hearAboutClinicFrom) {
      state.hearAboutClinicFrom = hearAboutClinicFrom
    },
    SET_ChronicDiseases(state, chronicDiseases) {
      state.chronicDiseases = chronicDiseases
    },
  },
  actions: {
    getAllPatients(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/patients', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPatientsCount() {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/patients/count')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getPatientById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/patient/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addPatient(ctx, addPatientObject) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/patient/add', addPatientObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deletePatient(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/patient/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePatientBasicInfo(ctx, updatePatientBasicInfoObject) {
      return new Promise((resolve, reject) => {
        // console.log(updatePatientBasicInfoObject)
        axios
          .put('/api/patient/edit_basic_info', updatePatientBasicInfoObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updatePatientDetailsInfo(ctx, updatePatientDetailsInfoObject) {
      return new Promise((resolve, reject) => {
        // console.log(updatePatientDetailsInfoObject)
        axios
          .put('/api/patient/edit_details_info', updatePatientDetailsInfoObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getAllHearAboutClinicFrom({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/hear_about_places')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const hearAboutClinicFrom = []
              response.data.Data.List.forEach(element => {
                hearAboutClinicFrom.push({
                  id: `${element.Id}-${element.Name}`,
                  label: element.Name,
                  value: element.Id,
                })
              })
              commit('SET_HearAboutClinicFrom', hearAboutClinicFrom)
              resolve(hearAboutClinicFrom)
            }
          })
          .catch(error => reject(error))
      })
    },
    getAllChronicDiseases({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/chronic_diseases')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const chronicDiseases = []
              response.data.Data.List.forEach(element => {
                chronicDiseases.push({
                  id: `${element.Id}-${element.Name}`,
                  label: element.Name,
                  value: element.Id,
                })
              })
              commit('SET_ChronicDiseases', chronicDiseases)
              resolve(chronicDiseases)
            }
          })
          .catch(error => reject(error))
      })
    },

  },
}
