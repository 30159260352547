import axios from '@axios'

export default {
  namespaced: true,
  state: {
    doctorOptions: [],
    patientOptions: [],
  },
  getters: {},
  mutations: {
    SET_DoctorOptions(state, doctorOptions) {
      state.doctorOptions = doctorOptions
    },
    SET_PatientOptions(state, patientOptions) {
      state.patientOptions = patientOptions
    },
  },
  actions: {
    getAllVisits(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/visits', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getVisitByAppointmentId(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/api/visit?appointmentId=${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateVisit(ctx, updateVisitDataObject) {
      return new Promise((resolve, reject) => {
        axios
          .put('/api/visit', updateVisitDataObject)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDoctorOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/doctors')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const doctors = []
              response.data.Data.List.forEach(element => {
                doctors.push({
                  id: element.Id,
                  name: element.FullName,
                })
              })
              commit('SET_DoctorOptions', doctors)
              resolve(doctors)
            }
          })
          .catch(error => reject(error))
      })
    },
    getPatientOptions({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/patients')
          .then(response => {
            if (response.status === 200 && response.data.IsSucceeded === true) {
              const patients = []
              response.data.Data.List.forEach(element => {
                patients.push({
                  id: element.Id,
                  name: element.FullName,
                })
              })
              commit('SET_PatientOptions', patients)
              resolve(patients)
            }
          })
          .catch(error => reject(error))
      })
    },
    removeAppointment(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/api/appointment/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
